<template>
  <div>
    <template v-if="bionic">
      <a v-if="!hideLink"
         href="#"
         :data-qa="`${dataQa}-requestSupport`"
         class="btn btn-bionic-nav-menu-item"
         @click.prevent="handleShow()">
        <fluency-icon type="help" class="mr-2" /> Request Support
      </a>
    </template>

    <t-modal id="backpack-forum-modal"
             v-model="localShow"
             :size="modalSize"
             centered
             :title="modalTitle"
             @shown="handleShown()"
             @hidden="resetState()">
      <forum-full v-if="fullModal" />
      <forum-create-topic v-else-if="authoring" ref="forumCreateTopic"
                          :btn-valid="topicValid"
                          @update:btn-valid="topicValid = $event"
                          :show-heading="false" />
      <forum-search v-else></forum-search>
      <template #modal-footer>
      <div class="m-0 flex-grow-1 d-flex align-items-end">
        <template v-if="authoring">
          <b-button variant="link" @click="$store.commit('forum/authoring', false)">Search Existing Posts</b-button>
          <b-button v-if="!fullModal" variant="primary" class="ml-auto" :disabled="!topicValid" @click="$refs.forumCreateTopic.submitTopic()">Submit</b-button>
        </template>
        <template v-else>
          <div>
            <div class="small text-muted mb-2">Not finding what you're looking for?</div>
            <b-button class="create-new-topic-btn" @click="$store.commit('forum/authoring', true)">Create A New Post</b-button>
          </div>
          <b-button v-if="!fullModal" variant="primary" class="ml-auto" @click="search()">Search</b-button>
        </template>
      </div>
      </template>
    </t-modal>
  </div>
</template>

<script>
import ForumSearch from '@/components/common/forum/forumSearch'
import ForumFull from '@/components/common/forum/forumFull'
import ForumCreateTopic from '@/components/common/forum/forumCreatTopic'

export default {
  name: 'ForumModal',
  components: { ForumCreateTopic, ForumFull, ForumSearch },
  props: {
    bionic: {
      type: Boolean,
      default: false
    },
    dataQa: {
      type: String,
      default: ''
    },
    hideLink: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      localShow: this.show,
      topicValid: false
    }
  },
  watch: {
    show: {
      immediate: true,
      handler: function (newVal) {
        if (newVal !== this.localShow) {
          this.localShow = newVal
        }
      }
    },
    localShow: {
      immediate: true,
      handler: function (newVal) {
        if (newVal !== this.show) {
          this.$emit('update:show', newVal)
        }
      }
    }
  },
  mounted () {
    this.$eventBus.$on('bionicOpenRequestSupportModal', this.handleRemoteShowTrigger)
  },
  computed: {
    fullModal () {
      return this.$store.getters['forum/fullModal']
    },
    modalSize () {
      return this.fullModal ? 'xxl' : 'sm'
    },
    modalTitle () {
      return this.authoring ? 'Create A New Post' : this.fullModal ? 'Welcome To Your Help Community' : 'Let\'s Find The Help You Need'
    },
    authoring () {
      return this.$store.getters['forum/authoring']
    },
    whatFixEnabled () {
      return this.$store.getters.user?.capabilities?.WhatFix
    }
  },
  methods: {
    initState () {
      this.$store.dispatch('forum/init')
    },
    resetState () {
      this.$store.commit('forum/resetState')
    },
    search () {
      this.$store.dispatch('forum/search')
    },
    handleRemoteShowTrigger () {
      if (this.bionic && this.hideLink) {
        this.handleShow()
      }
    },
    async handleShow () {
      if (this.whatFixEnabled) {
        await this.$store.commit('forum/authoring', true)
      }
      await this.initState()

      if (this.$route.name !== 'insights-feedback') {
        this.localShow = true
      }
      this.$emit('show')
    },
    handleShown () {
      const modal = document.getElementById('backpack-forum-modal')
      if (modal && !modal.hasAttribute('data-html2canvas-ignore')) {
        modal.setAttribute('data-html2canvas-ignore', 'true')
      }
    }
  }
}
</script>

<style lang="scss">
.forum-toggle {
  position: fixed;
  bottom: 0;
  right: 100px;
  color: $fluency-lightergray;
  background-color: $fluency-gray;
  border-radius: 6px 6px 0 0;
  z-index: 1100;
  .fluency-help {
    font-size: 15px;
    fill: $fluency-lightergray;
  }
  &:hover {
    background-color: lighten($fluency-gray, 10);
  }
}
</style>
